













import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ReportView from '@/components/helper/report-view.vue';

class PageReportRequest extends PageRequest {
    election: number = null;
    ban_type: number = null;
}

@Component({
    components: { ReportView },
})
export default class Blacklists extends ListBase {
    pagerequest: PageReportRequest = new PageReportRequest();
    entity: string = 'blacklist';

    columns = [
        { value: 'lawyer', text: 'Agremiado', type: 'text', sortable: false },
        { value: 'ban_type', text: 'Tipo de Bloqueo', type: 'text', sortable: false },
        { value: 'ip', text: 'IP', type: 'text', sortable: false },
        { value: 'browser_info', text: 'Informacion', type: 'text', sortable: false },
        { value: 'nro_attempts', text: 'Nro de Intentos', type: 'text', sortable: false },
    ];

    filterItems = [ 
        this.gItem({ type: 'object', prop: 'election', label: 'Eleccion', itemText: 'description', required: true, itemSubTitle: 'notes', itemChip: 'number', itemChipColor: 'state_color', size: 6, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'object', prop: 'ban_type', label: 'Tipo de Bloqueo', size: 6, hideDetails: true, generalType: 'T_LOCK', onchange: this.getpage }),

    ];

    /************* BOTONES DE ACCION ***************/
    async getpage() {
        let list: any = this.$refs.list
        await list.getpage();
    }
    
    created() {

    }
    
}
